.comment-content-container {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
}

.comments-content {
    margin-left: 15px;
}

.comments-user-profile-pic {
    width: 37px;
    height: 37px;
    border-radius: 18px;
    object-fit: cover;
}

.comment-occupation-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
}

.user-name-occupation-container {
    margin-left: 7px;
    background-color: #f2f2f2;
    width: 27rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

#dark .user-name-occupation-container {
    background-color: #282828;
}

.comment-options {
    display: flex;
    justify-content: space-between;
}

.user-first-last-name {
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}

.user-first-last-name:hover {
    color: #0766c2;
    text-decoration: underline;
}

.user-occupation {
    margin-top: 2px;
    font-size: 12px;
    color: rgb(132, 132, 132);
}

.user-comment {
    margin-top: 10px;
    font-size: 14px;
}

.editable-comment {
    font-size: 14px;
    margin-top: 10px;
}

.save-comment-button {
    margin-top: 10px;
    border: none;
    background-color: #0766c2;
    color: white;
    padding: 9px;
    border-radius: 18px;
    width: 127px;
    font-size: 15px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #0766c2;
}

.save-comment-button:hover {
    background-color: #015b90;
}

.cancel-edit-comment-button {
    margin-top: 10px;
    border: none;
    background-color: transparent;
    border: 1px solid gray;
    color: gray;
    padding: 9px;
    border-radius: 18px;
    width: 127px;
    font-size: 15px;
    cursor: pointer;
}

.cancel-edit-comment-button:hover {
    background-color: rgb(231, 231, 231);
}

.comment-dropdown {
    position: absolute;
    margin-left: 300px;
    margin-top: -45px;
    transform: translateY(100%);
    background-color: white;
    box-shadow: 2px 1px .2px rgba(1, 1, 1, 0.1),
        -2px -1px .2px rgba(1, 1, 1, 0.1);
    width: 195px;
    line-height: 2.2;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

#dark .comment-dropdown {
    background-color: #232323;
}

.edit-comment {
    font-size: 15px;
    color: rgb(87, 87, 87);
    cursor: pointer;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 17px;
}

#dark .edit-comment {
    color: white;
}

.delete-comment {
    font-size: 15px;
    color: rgb(87, 87, 87);
    cursor: pointer;
    padding-bottom: 7px;
    padding-left: 17px;
}

#dark .delete-comment {
    color: white;
}

.edit-comment:hover,
.delete-comment:hover {
    background-color: rgb(234, 234, 234);

}

#dark .edit-comment:hover, #dark .delete-comment:hover {
    background-color: #373737;

}

.edit-comment-logo,
.delete-comment-logo {
    margin-right: 16px;
}

#dark .edit-comment-logo, #dark .delete-comment-logo {
    color: rgb(156, 156, 156);
}