.create-like-button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-top: 10px;
    margin-left: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
}

.create-like-button-container:hover {
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
}

#dark .create-like-button-container:hover {
    background-color: rgb(104, 104, 104);
}

.fa-thumbs-up {
    font-size: 23px;
    transform: scaleX(-1);
}

#dark .fa-thumbs-up {
    color: #f4f4f4;
}

.create-like-text {
    color: #888;
    margin-left: 7px;
    font-weight: 700;
}

#dark .create-like-text {
    color: #f4f4f4;
}
