#dark body {
    background-color: rgb(10, 10, 10);
}

.main-page-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 30px;
    background-color: #f3f2ef;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}

#dark .main-page-container {
    background-color: rgb(17, 17, 17);
}

.main-page-feed {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.create-post-container {
    display: flex;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(210, 210, 210);
    border-radius: 8px;
    background-color: white;
    height: 70px;
    width: 500px;
    padding-left: 14px;
    padding-right: 14px;
}

#dark .create-post-container {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.post-prof-pic-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.create-post-profile-pic {
    height: 44px;
    width: 44px;
    margin-right: 8px;
}

.create-post-button {
    text-align: left;
    background-color: white;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 30px;
    padding-left: 20px;
    color: rgb(113, 113, 113);
    font-size: 14px;
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 450;
    height: 50px;
    min-width: 440px;
    max-width: 490px;
}

#dark .create-post-button {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
    color: white;
    border: 1px solid rgb(101, 101, 101);
}

.create-post-button:hover {
    background-color: rgb(236, 236, 236);
    cursor: pointer;
}

.feed-border {
    border: 1px solid rgb(221, 221, 221);
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 257px;
    padding-right: 257px;
}

#dark .feed-border {
    border: 1px solid rgb(29, 29, 29);
}

/*! ABOUT ME (LEFT SIDE OF MAIN PAGE) */

#dark .main-page-left-side-container {
    background-color: rgb(17, 17, 17);
}

.about-me-container {
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgb(223, 223, 223);
    background-color: rgb(253, 253, 253);
    width: 230px;
    height: 270px;
}

#dark .about-me-container {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.about-me-background-img {
    height: 58px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.about-me-profile-pic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -200%);
    border: 2px solid white;
    border-radius: 40px;
    z-index: 1;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

#dark .about-me-profile-pic {
    border: 2px solid #1a1a1a;
}

.about-me-firstname {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-weight: 500;
}

#dark .about-me-firstname {
    color: white;
}

.about-me-occupation {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    font-size: 12.5px;
    color: rgb(149, 149, 149);
    border-bottom: 1px solid rgb(223, 223, 223);
    margin-bottom: 10px;
    padding-bottom: 15px;
}

#dark .about-me-occupation {
    color: rgb(173, 171, 171);
    border-bottom: 1px solid rgb(49, 49, 49);
}

.about-me-bio {
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 13.5px;
    margin-left: 15px;
    margin-right: 15px;
}

#dark .about-me-bio {
    color: rgb(173, 171, 171);
}

.about-me-img {
    display: flex;
    justify-content: center;
}

.socials-container {
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgb(223, 223, 223);
    background-color: white;
    width: 230px;
    height: 115px;
    margin-top: 13px;
    font-size: 14px;
}

#dark .socials-container {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.socials-title {
    display: flex;
    font-weight: 600;
    margin-top: 16px;
    margin-left: 13px;
}

#dark .socials-title {
    color: white;
}

.mainpage-github {
    margin-top: 13px;
    margin-left: 13px;
}

.github-img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
}

.linkedin-text {
    margin-bottom: 13px;
    font-weight: 600;
    color: #0766c2;
}

#dark .linkedin-text {
    color: #70b5f9;
}

.github-text {
    font-weight: 600;
    color: #0766c2;
}

#dark .github-text {
    color: #70b5f9;
}

.linkedin-text:hover,
.github-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.switch label {
    margin: 10px;
}

#light .switch label {
    color: black;
}

#dark .switch label {
    color: white;
}