/* TODO Add site wide styles */

body {
    background-color: #f3f2ef;
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body.dark {
    background-color: rgb(14, 14, 14);
}

div[contenteditable]:focus {
    outline: none;
}

li {
    list-style: none;
}

img {
    object-fit: cover;
}

.error-messages {
    display: flex;
    color: red;
    font-size: 14px;
    margin-bottom: 13px;
}

.coming-soon-container {
    height: 250px;
    width: 420px;
    text-align: center;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dark .coming-soon-container {
    background-color: #1a1a1a;
}

.coming-soon-title {
    font-size: 33px;
    line-height: 1.5;
}

.dark .coming-soon-title {
    color: white;
}

.coming-soon-modal-exit {
    font-size: 18px;
    font-weight: 200;
    border-radius: 10px;
    padding: 12px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: transparent;
    border: 1px solid rgb(212, 212, 212);
}

.dark .coming-soon-modal-exit {
    color: white;
    border: 1px solid rgb(62, 62, 62);
}

.coming-soon-modal-exit:hover {
    cursor: pointer;
    background-color: rgb(234, 234, 234);
}

.dark .coming-soon-modal-exit:hover {
    background-color: rgb(62, 62, 62);
}

.confirm-delete-container {
    height: 220px;
    width: 400px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -150%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dark .confirm-delete-container {
    background-color: #1a1a1a;
}

.confirm-delete-title {
    font-size: 20px;
    margin-bottom: 30px;
    padding-top: 20px;
}

.dark .confirm-delete-title {
    color: white;
}

.confirm-delete-text {
    color: gray;
    text-align: center;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 10px;
}

.dark .confirm-delete-text {
    color: rgb(150, 150, 150);
}

.confirm-border {
    display: flex;
    width: 100%;
    border-top: 1px solid rgb(230, 230, 230);
    margin-bottom: 15px;
    margin-top: 28px;
}

.dark .confirm-border {
    border-top: 1px solid rgb(47, 47, 47);
}

.confirm-buttons-container {
    display: flex;
    margin-left: 180px;
}

.confirm-delete-button {
    background-color: #0766c2;
    border: 1px solid #0766c2;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
    padding-left: 22px;
    padding-right: 22px;
    border-radius: 30px;
    font-size: 15px;
}

.dark .confirm-delete-button {
    background-color: #70b5f9;
    border: 1px solid #70b5f9;
    color: #1a1a1a;
}

.confirm-delete-button:hover {
    background-color: #015b90;
}

.dark .confirm-delete-button:hover {
    background-color: #5b9ee8;
}

.confirm-cancel-button {
    background-color: #ffffff;
    color: black;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
    padding: 8px;
    padding-left: 22px;
    padding-right: 22px;
    border-radius: 30px;
    margin-right: 6px;
    font-size: 15px;
}

.dark .confirm-cancel-button {
    background-color: #3b3b3b;
    color: white;
    border: 1px solid #3b3b3b;
}

.confirm-cancel-button:hover {
    background-color: rgb(242, 242, 242);
}

.dark .confirm-cancel-button:hover {
    background-color: rgb(51, 51, 51);
}