.login-container {
    height: 520px;
    width: 480px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -67%);
}

.login-title {
    margin-left: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
    font-size: 22px;
}

.login-form {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
}

.login-label-container {
    /* border: 1px solid #9d9d9d;
    border-radius: 8px; */
}

.login-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;

}

.login-label-title {
    font-size: 16px;
    color: rgb(125, 125, 125);
    margin-bottom: 7px;
}


.login-email,
.login-password {
    font-size: 16px;
    padding:14px;
    padding-bottom: 15px;
    border: none;
    border-radius: 8px;
    border: 1px solid #9d9d9d;
    border-radius: 8px;
}

.login-button {
    background-color: #0766c2;
    border: none;
    border-radius: 23px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 25px;
    touch-action: manipulation;
}

.login-button:hover {
    background-color: #015b90;
}

.alternate-logins {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 200;
    padding: 0 10px;

}

.alternate-logins::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-right: 16px;
}

.alternate-logins::after {
    content: "";
    /* display: block; */
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-left: 16px;
}

.modal-demo-button {
    margin-top: 15px;
    background: transparent;
    border-radius: 23px;
    border-width: 1px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 20px;
    touch-action: manipulation;
}

.modal-demo-button:hover {
    background-color: rgb(246, 246, 246);
}

.login-signup-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 16px;
}

.login-signup-button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #0766c2;
    cursor: pointer;
    text-decoration: none;
    margin-left: 5px;
}

.login-signup-button:hover {
    text-decoration: underline;
}