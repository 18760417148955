.app-info-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid rgb(223, 223, 223);
    background-color: white;
    width: 300px;
    max-height: fit-content;
    padding: 13px
}

#dark .app-info-container {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.app-info-container {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.app-info-container.show-all {
    max-height: none;
}

.app-info-title {
    display: flex;
    font-size: 18px;
    margin-top: 5px;
    font-weight: 600;
}

#dark .app-info-title {
    color: white;
}

.app-info {
    font-size: 13px;
    margin-top: 10px;
    line-height: 1.35;
    margin-bottom: 10px;
}

#dark .app-info {
    color: rgb(179, 179, 179);
}

.app-info-feature {}

.feature-title {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
}

#dark .feature-title {
    color: white;
}

.feature-description {
    font-size: 14px;
    color: rgb(149, 149, 149);
    margin-left: 22px;
}

#dark .feature-description {
    color: rgb(179, 179, 179);
}

.toggle-features-container {
    margin-top: 15px;
    margin-left: 15px;
}

.toggle-features-button {
    color: rgb(113, 113, 113);
    border: none;
    background-color: white;
    font-size: 16px;
    padding: 5px;
}

#dark .toggle-features-button {
    color: rgb(179, 179, 179);
    background-color: #1a1a1a;
}

.toggle-features-button:hover {
    background-color: rgb(238, 238, 238);
    cursor: pointer;
    border-radius: 4px;
}

#dark .toggle-features-button:hover {
    background-color: rgb(49, 49, 49);
}

.fa-chevron-down {
    color: rgb(113, 113, 113);
    margin-left: 5px;
    font-size: 16px;
}

.languages-used-container {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 19px;
}

.languages-row {
    font-size: 13.5px;
    color: rgb(149, 149, 149);
    margin-bottom: 9px;
}

.language {
    margin-left: 8px;
    margin-right: 8px;
}

.linkedMe-text-logo-container {
    margin-top: 7px;
    font-size: 14px;
}

.linked {
    color: #0766c2;
    font-weight: 600;
    margin-right: 1px;
}

#dark .linked {
    color: white;
}

.me {
    background-color: #0766c2;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding-left: 1.36px;
    padding-right: 1.36px;
    padding-bottom: 1px;
    border-radius: 3px;
    margin-right: 6px;
}

#dark .me {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}

.linkedme-me-year {
    color: rgb(149, 149, 149);
}

#dark .linkedme-me-year {
    color: #ffffff;
    ;
}