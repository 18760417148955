.fa-ellipsis {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.post-options {
    position: absolute;
    margin-left: -180px;
    margin-top: -90px;
    transform: translateY(100%);
    background-color: white;
    box-shadow: 2px 1px .2px rgba(1, 1, 1, 0.1),
        -2px -1px .2px rgba(1, 1, 1, 0.1);
    width: 195px;
    line-height: 2.2;
    border-radius: 3.65px;
}

#dark .post-options {
    background-color: #232323;
}

.post-options-container {
    display: flex;
    flex-direction: column;
}

.post-options-edit-container,
.post-options-delete-container {
    padding-left: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.post-options-edit-symbol, .post-options-delete-symbol {
    margin-right: 5px;
}

#dark .post-options-edit-symbol,
.post-options-delete-symbol {
    color: rgb(156, 156, 156);
}

.post-options-edit,
.post-options-delete {
    text-align: left;
    border: none;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    width: 100%;
}

#dark .post-options-edit,
#dark .post-options-delete {
    background-color: #232323;
    color: white;
}

.post-options-edit-container:hover,
.post-options-delete-container:hover {
    background-color: rgb(214, 214, 214);
}

#dark .post-options-edit-container:hover, 
#dark .post-options-delete-container:hover {
    background-color: #373737;
}

.post-options-edit-container:hover>button,
.post-options-delete-container:hover>button {
    background-color: rgb(214, 214, 214);
}

#dark .post-options-edit-container:hover>button,
#dark .post-options-delete-container:hover>button {
    background-color: #373737;
}