.feed-container {
  display: flex;
  justify-content: center;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(210, 210, 210);
  background-color: white;
}

#dark .feed-container {
  background-color: #1a1a1a;
  color: white;
  border: 1px solid #1a1a1a;
}

.user-post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 530px;
  /* padding: 14px; */
}

.post-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 14px
}

.profile-image-info-container {
  display: flex;
  flex-direction: row;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 25px;
}

.post-info {
  display: flex;
  flex-direction: column;
  line-height: 1.35;
  font-size: 14px;
}

.user-name {
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}

.user-name:hover {
  color: #0766c2;
  text-decoration: underline;
}

#dark .user-name:hover {
  color: #58a3ee;
}

.postcard-user-occupation {
  font-size: 13px;
  color: rgb(149, 149, 149);
}

.post-date {
  color: rgb(139, 139, 139);
  font-size: 13px;
}

.date-global-container {
  display: flex;
}

.global-img {
  width: 16px;
  height: 16px;
  margin-left: 2px;
}

.post-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
}

.user-posts {
  word-wrap: break-word;
  max-width: 100%;
  margin-right: 14px;
  margin-left: 14px;

}

.post-image {
  display: flex;
  margin-top: 10px;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
}

.likes-comments-tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.likes-tab {
  padding-left: 14px;
  display: flex;
  justify-content: end;
  margin-top: 10px;
  font-size: 13px;
  color: rgb(139, 139, 139);
  margin-bottom: 2px;
}

.likes-tab-icon {
  margin-top: 2px;
  width: 13.5px;
  height: 13.5px;
  object-fit: cover;
  margin-right: 3px;
  
}

.comments-tab {
  padding-right: 14px;
  display: flex;
  justify-content: end;
  margin-top: 10px;
  font-size: 13px;
  color: rgb(139, 139, 139);
  margin-bottom: 2px;
}

.post-card-border {
  margin-top: 10px;
  border: 1px solid rgb(244, 244, 244);
}

#dark .post-card-border {
  border: 1px solid rgb(46, 46, 46);
}