.newPost-container {
    height: 400px;
    width: 560px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -95%);
}

.dark .newPost-container {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.newPost-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgb(226, 226, 226);
}

.dark .newPost-title-container {
    border-bottom: 1px solid rgb(62, 62, 62);
}

.newPost-title {
    padding: 20px;
    padding-bottom: 15px;
    font-size: 21px;
    display: flex;
    color: rgb(62, 62, 62);
    font-weight: 300;
}

.dark .newPost-title {
    color: white;
}

.x {
    padding: 20px;
    padding-bottom: 15px;
    font-size: 21px;
    display: flex;
    color: rgb(62, 62, 62);
    cursor: pointer;
}

.dark .x {
    color: white;
}

.create-post-user-container {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 18px;
}

.create-post-prof-pic {
    width: 45px;
    height: 45px;
}

.newPost-firstname {
    font-weight: 500;
    font-size: 17px;
    margin-left: 5px;
}

.dark .newPost-firstname {
    color: white;
}

.newPost-form {
    padding: 20px;
}


.newPost-text {
    display: flex;
    justify-content: center;
    width: 475px;
    height: 150px;
    resize: none;
    border: none;
    font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: 300;
}

.dark .newPost-text {
    color: white;
    background-color: #1a1a1a;
}

.newPost-text:focus {
    outline: none;
}


.post-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 17px;
}

.post-image-text-container {
    display: flex;
    align-items: center;
}

.image-input {
    border: 1px solid rgb(205, 205, 205);
    padding: 3px;
    margin-left: 5px;
    font-size: 14px;
}

.dark .image-input {
    border: 1px solid rgb(62, 62, 62);
    color: white;
    background-color: #1a1a1a;
}

.fa-image {
    font-size: 20px;
}

.dark .fa-image {
    color: white;
}

.image-input:focus {
    outline: none;
}

.newPost-button {
    border: none;
    color: white;
    background-color: #0b66c2;
    width: 67px;
    height: 33px;
    border-radius: 18px;
    font-size: 15.8px;
    cursor: pointer;
}

.newPost-button:hover {
    background-color: #015b90;
}

.newPost-button.submitting {
    cursor: not-allowed;
    background-color: #ebebeb;
    border-color: #ebebeb;
    color: #a4a4a4;
    margin-right: 30px;
    width: 97px;
}

.newPost-button.disabled {
    cursor: not-allowed;
    background-color: #ebebeb;
    border-color: #ebebeb;
    color: #a4a4a4;
}

.dark .newPost-button {
    background-color: #70b5f9;
}

.dark .newPost-button.disabled {
    cursor: not-allowed;
    background-color: #383d40;
    border-color: #383d40;
    color: #878b8c;
}

.dark .newPost-button:not(.disabled) {
    color: #fff;
    /* White text color */
}