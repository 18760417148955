.connections-page-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 80px;
}

/*! connections card */
.connections-card {
    display: flex;
    flex-direction: column;
    box-shadow: ÷ 0 0 1px rgba(0, 0, 0, 0.9);
    border: 1px solid rgb(230, 230, 230);
    background-color: white;
    width: 700px;
    height: 100%;
    margin-right: 29px;
    border-radius: 10px;
}

#dark .connections-card {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.connections-network {
    padding-top: 13px;
    padding-left: 15px;
    padding-bottom: 17px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 350;
    border-bottom: 1px solid rgb(231, 231, 231);
}

#dark .connections-network {
    color: white;
    border-bottom: 1px solid #2d2c2c;
}

.connections-tabs {
    margin-left: 15px;
    margin-bottom: 28px;
    font-weight: 450;
}

.connections-following,
.connections-followers {
    cursor: pointer
}

.connections-tabs span {
    cursor: pointer;
    margin-right: 10px;
    padding: 5px;
}

#dark .connections-tabs span {
    cursor: pointer;
    margin-right: 10px;
    padding: 5px;
}

.active-tab {
    color: #008000;
    border-bottom: 2px solid #008000;
}

#dark .active-tab {
    color: #3fc786;
    border-bottom: 1px solid #3fc786;
}

#dark .connections-tabs span:not(.active-tab) {
    color: white;
  }

.connections-count {
    padding-left: 15px;
    padding-right: 20px;
    margin-bottom: 10px;
}

#dark .connections-count {
    color: white;
}

.connections-sort-container {
    border-bottom: 1px solid rgb(231, 231, 231);
    padding-bottom: 15px;
    margin-bottom: 10px;
}

#dark .connections-sort-container {
    border-bottom: 1px solid #2d2c2c;
}

.connections-sort-label {
    padding-left: 20px;
    font-weight: 200;
    font-size: 13px;
    margin-right: 3px;
}

#dark .connections-sort-label {
    color: white;
}

.sort-connections {
    padding-right: 4px;
    font-weight: 600;
    font-size: 13px;
    color: gray;
    background-color: white;
    border: none;
}

#dark .sort-connections {
    color: white;
    background-color: #1a1a1a;
}

.sort-connections:focus {
    outline: none;
}

.connections-user-card-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-left: 15px;
}

.connections-user-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}

.connections-profile-pic {
    width: 65px;
    height: 65px;
    border-radius: 32px;
}

.connections-user-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    line-height: 1.4;
}

.connections-name {
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    color: black;
}

#dark .connections-name {
    color: white;
}

.connections-name:hover {
    text-decoration: underline;
}

.connections-occupations {
    font-size: 13px;
    color: gray;
}

#dark .connections-occupations {
    color: rgb(138, 138, 138);
}

.connection-options-container {
    margin-right: 28px;
}

#dark .connection-options {
    color: white;
}

.follower-options {
    font-size: 17px;
    position: absolute;
    margin-left: -100px;
    margin-top: -30px;
    transform: translateY(100%);
    background-color: white;
    box-shadow: 2px 1px .2px rgba(1, 1, 1, 0.1),
        -2px -1px .2px rgba(1, 1, 1, 0.1);
    /* width: 195px; */
    line-height: 2.2;
    border-radius: 3.65px;
}

#dark .follower-options {
    background-color: #232323;
}


/*! Right side */
.right-side-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 310px;
    height: 200px;
    padding-bottom: 70px;
}