.post-card-buttons-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.create-comment-button-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.create-commment-text {
  color: #888;
  margin-left: 7px;
  font-weight: 700;
}

#dark .create-commment-text {
  color: #f4f4f4;
}

.create-comment-button-container:hover {
  background-color: rgb(228, 228, 228);
  border-radius: 5px;
}


#dark .create-comment-button-container:hover {
  background-color: rgb(104, 104, 104);
}

.create-comment-container {
  margin-left: 14px;
  margin-right: 14px;
}

.comments-tab:hover {
  color: #0766c2;
  text-decoration: underline;
  cursor: pointer;
}

#dark .comments-tab:hover {
  color: #4996e3;
}

.fa-comment-dots {
  font-size: 23px;
}

#dark .fa-comment-dots {
  color: #f4f4f4;
}

.comment-form {
  margin-bottom: 15px;
}

.prof-pic-comment-creation-container {
  flex-direction: row;
  margin-top: 15px;
}

.comment-prof-pic {
  width: 43px;
  height: 43px;
  border-radius: 20px;
  object-fit: cover;
  margin-right: 8px;
}

.new-comment-textarea {
  font-family: system-ui, -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 450px;
  padding-left: 20px;
  padding-bottom: 0px;
  padding-top: 10px;
  box-sizing: border-box;
  border-radius: 25px;
  resize: vertical;
  height: auto;
  min-height: 40px;
  border-color: rgb(196, 196, 196);
}

#dark .new-comment-textarea {
  background-color: #1d2226;
  color: white;
  border: 1.5px solid rgb(101, 101, 101);
}