.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.nav-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  padding: 10px;
  z-index: 998;
}

#dark .nav-bar-container {
  background-color: #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
}

.nav-logo-search-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 54.7rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 57px;
  background-color: white;
  z-index: 998;
}

#dark .nav-logo-search-container {
  background-color: #1a1a1a;
}

.linkedme-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0766c2;
  color: white;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 1px;
  margin-left: 80px;
  width: 40px;
  height: 37px;
  border-radius: 3px;
  margin-right: 12px;
  cursor: pointer;
}

#dark .linkedme-logo {
  background-color: #eaeaea;
  color: #1a1a1a;
}

.logo-text {
}

/*! Search Bar */
.search-bar {
  border: none;
  font-size: 14px;
  padding: 6px 8px;
  margin-right: 12px;
  width: 260px;
  height: 24px;
  background-color: #eef3f7;
}

#dark .search-bar {
  background-color: #25282d;
  color: white;
}

.live-search-list {
  position: absolute;
  background-color: white;
  border: 1px rgb(210, 210, 210) solid;
  min-width: 400px;
  top: 57px;
  border-radius: 8px;
  z-index: 2;
  margin-left: 230px;
}

#dark .live-search-list {
  background-color: #1a1a1a;
  border: 1px solid #232323;
}

.live-search-list:empty {
  border: none;
}

@media only screen and (max-width: 888px) {
  .live-search-list {
    display: none;
  }
}

.search-list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 5px;
  padding-left: 22px;
  text-decoration: none;
  color: black;
}

#dark .search-list-container {
  color: white;
}

.search-list-container:hover {
  background-color: #e7e7e7;
}

#dark .search-list-container:hover {
  background-color: #232323;
}

.occupation {
  font-size: 12px;
  font-weight: 400;
  color: gray;
}

.search-prof-pic-container {
  display: flex;
  justify-content: end;
}

.search-prof-pic {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 35px;
}

.fa-magnifying-glass {
  font-size: 12px;
  color: rgb(68, 68, 68);
  padding-right: 8px;
}

#dark .fa-magnifying-glass {
  color: rgb(218, 218, 218);
}

.live-search-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}


/* --------------------------------------------- */

.nav-links {
  position: fixed;
  right: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 57px;
  margin-right: 140px;
}


.nav-item {
  list-style: none;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-top: 4px;
  cursor: pointer;
  padding-right: 5px;
  text-decoration: none;
  color: #000;
}

.fa-network-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.fa-user-group{
  color: black;
  font-size: 18.7px;
}

#dark .fa-user-group {
  color: white;
}

.network-text {
  font-size: 13px;
  font-weight: 270;
  margin-top: 3px;
}

#dark .network-text {
  color: white;
}

.fa-house-chimney {
  font-size: 18.7px;
}

#dark .fa-house-chimney {
  color: white;
}

.home-text {
  font-size: 13px;
  font-weight: 270;
  margin-top: 3px;
}

#dark .home-text {
  color: white;
}

/*! Nav Bar when signed out */
.nav-bar-container-new {
  display: flex;
  justify-content: space-between;
  /* justify-content: end; */
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 10px;
  background-color: white;
}

.nav-linkedme-logo {
  margin-left: 80px;
  margin-bottom: 10px;
  cursor: pointer;
}

.nav-logo-section-1,
.nav-logo-section-2 {
  color: #0766c2;
  font-weight: 630;
  font-size: 35px;
}

.nav-logo-section-2 {
  background-color: #0766c2;
  color: white;
  border-radius: 3px;
  font-size: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3.1px;
  margin-left: 3px;
}


.nav-login-signup-container {
  margin-right: 80px;
  display: flex;
  align-items: center;
}

.nav-socials-container {
  display: flex;
  color: gray;
}

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-right: 30px;
  cursor: pointer;
}

.socials-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-right: 20px;
  cursor: pointer;
}

.nav-socials-title {
  margin-top: 3px;
  text-align: center;
  font-size: 14px;
}

.fa-github {
  font-size: 20px;
  color: gray;
}
.fa-link {
  font-size: 20px;
  color: gray;
}

.nav-border::after {
  display: flex;
  align-items: center;
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(134, 134, 134, 0.6);
  margin-left: 10px;
  margin-right: 10px;
}

.nav-login {
  font-size: 18px;
  background-color: transparent;
  border: 1px solid #0766c2;
  color: #0766c2;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.nav-login:hover {
  background-color: #eff6fd;
}

.nav-signup {
  font-size: 18px;
  background-color: transparent;
  border: none;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 25px;
  cursor: pointer;
  color: rgb(93, 93, 93);
}

.nav-signup:hover {
  background-color: rgb(237, 237, 237);
}