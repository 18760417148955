.user-profile-container {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

/*! Profile Card */
.user-profile-card {
    display: flex;
    position: relative;
    /* flex-direction: column; */
    justify-content: center;
    width: 770px;
    height: 380px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

#dark .user-profile-card {
    background-color: #1a1a1a;
    color: white;
}

.profile-card-content {
    display: flex;
    flex-direction: column;
}

.profile-background {
    object-fit: cover;
    height: 175px;
    width: 770px;
    border-radius: 8px;
}

.profile-card-picture {
    width: 130px;
    height: 130px;
    border-radius: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-265%, -80%);
    border: 3.7px solid white;
    z-index: 1;
    object-fit: cover;
}

#dark .profile-card-picture {
    border: 3.7px solid #1a1a1a;
}

.profile-card-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-info-section-1 {
    display: flex;
    flex-direction: column;
    margin-top: 55px;
    margin-left: 22px;
}

.profile-name {
    font-size: 24px;
    font-weight: 450;
}

.profile-occupation {
    margin-top: 3px;
    font-size: 15.5px;
}

.profile-location {
    margin-top: 7px;
    font-size: 13px;
    color: gray;
}

#dark .profile-location {
    color: #b3b3b3;
}

.profile-info-section-2-container {
    display: flex;
    align-items: end;
    flex-direction: column;
    margin-top: 35px;
    margin-right: 22px;
}

.profile-edit-button {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 20px;
    font-size: 23px;
    border: none;
    background-color: transparent;
    transform: scaleX(-1);
}

#dark .profile-edit-button {
    color: white;
}

.profile-edit-button:hover {
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

#dark .profile-edit-button:hover {
    background-color: #3a3838;
}

.profile-info-section-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.education-pic {
    width: 43px;
    height: 43px;
    object-fit: cover;
    margin-right: 6px;
    text-align: left;
}

.education-text {
    width: 140px;
    font-size: 13px;
    font-weight: 600;
}

.profile-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.profile-connect-button {
    border: none;
    background-color: #0766c2;
    color: white;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    margin-right: 10px;
}

#dark .profile-connect-button {
    background-color: #3a3838;
    color: white;
}

.fa-user-plus {
    font-size: 13px;
    margin-right: 3px;
}

.profile-connect-button:hover {
    background-color: #015b90;
}

#dark .profile-connect-button:hover {
    background-color: #4a4848;
}

.profile-message-button {
    border: 1px solid;
    border-color: #0766c2;
    color: #0766c2;
    background-color: transparent;
    padding: 8px;
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 20px;
    font-size: 15px;
    cursor: pointer;
}

#dark .profile-message-button {
    border-color: #70b5f9;
    color: #1a1a1a;
    background-color: #70b5f9;
}

.profile-message-button:hover {
    background-color: #eef6fe;
}

#dark .profile-message-button:hover {
    background-color: #8ac1f8;
    border-color: #8ac1f8;
}

/*! About Card */
.user-about-card {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 710px;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

#dark .user-about-card {
    background-color: #1a1a1a;
}

.about-card-title {
    font-weight: 600;
    font-size: 18.5px;
    margin-bottom: 15px;
}

#dark .about-card-title {
    color: white;
}

.about-card-text {
    color: gray;
    font-size: 13.7px;
    line-height: 1.3;
}

#dark .about-card-text {
    color: #d7d7d7;
}

/*! Activity Card */
.user-activity-card {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 710px;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

#dark .user-activity-card {
    background-color: #1a1a1a;
}

.activity-card-title {
    font-weight: 600;
    font-size: 18.5px;
    margin-bottom: 15px;
}

#dark .activity-card-title {
    color: white;
}

.activity-card-subtitle {
    font-size: 13px;
    color: gray;
    margin-bottom: 7px;
}

#dark .activity-card-subtitle {
    color: #d7d7d7;
}

.activity-card-posts {
    font-size: 13.7px;
    line-height: 1.4;
}

#dark .activity-card-posts {
    color: #d7d7d7;
}

/*! Education Card */
.user-education-card {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 710px;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

#dark .user-education-card {
    background-color: #1a1a1a;
}

.education-card-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

#dark .education-card-title {
    color: white;
}

.education-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.educatin-card-pic {
    width: 55px;
    height: 55px;
    object-fit: cover;
}

.education-card-achievements-container {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.education-card-achievements-info {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
}

#dark .education-card-achievements-info{
    color: white;
}

.education-card-education {
    font-weight: 500;
    font-size: 15.6px;
}

.education-card-studyfield {}

.education-card-education-year {
    color: gray;
    font-size: 13px;
}

#dark .education-card-education-year {
    color: #d7d7d7;
}

/*! Footer Card  */
.user-footer-card {
    display: flex;
    flex-direction: row;
    /* width: 875px; */
    justify-content: center;
    margin-top: 55px;
}

.footer-card-row-1 {
    display: flex;
    flex-direction: column;
    /* margin-right: 80px; */
}

.foot-card-logo-container {}

.footer-card-logo-section-1 {
    color: #0766c2;
    font-weight: 600;
    margin-right: 1px;
    font-size: 22px;
}

.footer-card-logo-section-2 {
    background-color: #0766c2;
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 4px;
    border-radius: 3px;
    margin-right: 6px;
}

.footer-language-row-1 {
    font-size: 12px;
    margin-top: 13px;
    font-weight: 500;
    color: gray;
}

.footer-card-logo-year {
    font-size: 12px;
    margin-top: 20px;
    /* margin-left: 60px; */
    /* display: flex;
    align-items: center; */
    font-weight: 500;
    color: rgb(149, 149, 149);
}

.footer-card-row-2 {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-right: 28px;
}

.footer-card-row-3 {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 58px;
    margin-right: 28px;
}

.footer-card-row-4 {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 58px;
    margin-right: 28px;
}

.footer-card-row-5 {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 38px;
}

.footer-language-links {
    font-size: 12px;
    margin-top: 13px;
    font-weight: 500;
    color: gray;
}

.footer-language-links:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

.footer-symbol {
    font-size: 20px;
    margin-right: 3px;
}

.footer-symbol:hover {
    color: black;
}